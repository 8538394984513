import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { getApp, initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, onAuthStateChanged, getIdToken, signInWithCustomToken } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { initializeAppCheck, ReCaptchaEnterpriseProvider, getToken } from 'firebase/app-check';

import Bugsnag from '@bugsnag/js';
import { onFinish } from "../../helpers";

const initialContext = {
    isInApp: false,
    loading: true,
    isAuthenticate: false,
    status: null,
    functions: null,
    locale: 'en_GB',
    hasStepper: false,
    step: 0,
    uid: '',
    hasPayment: true,
    isWaiting: false,
};

const StateContext = createContext(initialContext);
const DispatchContext = createContext(undefined);

const ActionTypes = {
    SET_IS_IN_APP: 'SET_IS_IN_APP',
    SET_IS_AUTHENTICATE: 'SET_IS_AUTHENTICATE',
    SET_STATUS: 'SET_STATUS',
    LOADING_SUCCESS: 'LOADING_SUCCESS',
    SET_LOCALE: 'SET_LOCALE',
    SET_HAS_STEPPER: 'SET_HAS_STEPPER',
    SET_STEP: 'SET_STEP',
    SET_UID: 'SET_UID',
    SET_IS_WAITING: 'SET_IS_WAITING',
};

/**
 * Takes an HTTP status code and returns the corresponding ErrorCode.
 * This is the standard HTTP status code -> error mapping defined in:
 * https://github.com/googleapis/googleapis/blob/master/google/rpc/code.proto
 *
 * @param status An HTTP status code.
 * @return The corresponding ErrorCode, or ErrorCode.UNKNOWN if none.
 */
const codeForHTTPStatus = (status) => {
    // Make sure any successful status is OK.
    if (status >= 200 && status < 300) {
        return 'ok';
    }
    switch (status) {
        case 0:
            // This can happen if the server returns 500.
            return 'internal';
        case 400:
            return 'invalid-argument';
        case 401:
            return 'unauthenticated';
        case 403:
            return 'permission-denied';
        case 404:
            return 'not-found';
        case 409:
            return 'aborted';
        case 429:
            return 'resource-exhausted';
        case 499:
            return 'cancelled';
        case 500:
            return 'internal';
        case 501:
            return 'unimplemented';
        case 503:
            return 'unavailable';
        case 504:
            return 'deadline-exceeded';
        default: // ignore
    }
    return 'unknown';
};

const errorCodeMap = {
    OK: 'ok',
    CANCELLED: 'cancelled',
    UNKNOWN: 'unknown',
    INVALID_ARGUMENT: 'invalid-argument',
    DEADLINE_EXCEEDED: 'deadline-exceeded',
    NOT_FOUND: 'not-found',
    ALREADY_EXISTS: 'already-exists',
    PERMISSION_DENIED: 'permission-denied',
    UNAUTHENTICATED: 'unauthenticated',
    RESOURCE_EXHAUSTED: 'resource-exhausted',
    FAILED_PRECONDITION: 'failed-precondition',
    ABORTED: 'aborted',
    OUT_OF_RANGE: 'out-of-range',
    UNIMPLEMENTED: 'unimplemented',
    INTERNAL: 'internal',
    UNAVAILABLE: 'unavailable',
    DATA_LOSS: 'data-loss'
};

let baseUri = null;
let appCheck = null;
export const callable = async (name, data) => {
    if (!baseUri) {
        if (process.env.NODE_ENV === 'development') {
            baseUri = `http://localhost:5001/${getApp().options.projectId}/europe-west1/api/api`;
        } else {
            baseUri = '/api';
        }
    }

    console.log({ appCheck })
    let appCheckTokenResponse = null;
    try {
        appCheckTokenResponse = await getToken(appCheck, false);
    } catch (err) {
        // Handle any errors if the token was not retrieved.
        return;
    }
    console.log({ appCheckTokenResponse: !!appCheckTokenResponse })

    const auth = getAuth()
    const idToken = auth.currentUser ? await getIdToken(auth.currentUser) : null;
    const headers = {
        'Content-Type': 'application/json',
        'X-Firebase-AppCheck': appCheckTokenResponse?.token,
    }
    if (idToken) headers.Authorization = `Bearer ${idToken}`;


    return fetch(`${baseUri}/${name}`, {
        headers,
        method: 'POST',
        body: JSON.stringify({ data: data !== undefined ? data : null }),
    }).then(async (response) => {
        if (response.ok) {
            return response.json().catch(() => null);
        }

        const json = await response.json().catch(() => null);
        const errorJSON = json?.error || {};
        let code = codeForHTTPStatus(response.status);
        let description = code;
        const status = errorJSON.status;
        if (typeof status === 'string') {
            if (!errorCodeMap[status]) {
                // They must've included an unknown error code in the body.
                throw {
                    code,
                    message: errorJSON.message || code,
                    details: errorJSON.details || null,
                };
            }
            code = errorCodeMap[status];
            description = status;
        }

        throw {
            code,
            message: description,
            details: errorJSON.details || null,
        };
    });
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_IS_IN_APP:
            return {
                ...state,
                isInApp: payload,
            };
        case ActionTypes.SET_IS_AUTHENTICATE:
            return {
                ...state,
                isAuthenticate: payload,
            };
        case ActionTypes.SET_STATUS:
            return {
                ...state,
                status: payload,
            };
        case ActionTypes.LOADING_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case ActionTypes.SET_LOCALE:
            return {
                ...state,
                locale: payload,
            };

        case ActionTypes.SET_HAS_STEPPER:
            return {
                ...state,
                hasStepper: payload,
            };

        case ActionTypes.SET_STEP:
            return {
                ...state,
                step: payload,
            };

        case ActionTypes.SET_UID:
            return {
                ...state,
                uid: payload,
            };

        case ActionTypes.SET_IS_WAITING:
            return {
                ...state,
                isWaiting: payload,
            };

        default:
            return state;
    }
};

export const AppProvider = ({ children, hasPayment = true }) => {
    const [state, dispatch] = useReducer(reducer, { ...initialContext, hasPayment });

    return (
        <StateContext.Provider value={state} displayName="AppState">
            <DispatchContext.Provider value={dispatch} displayName="AppDispatch">
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};

const fetchFirebaseConfig = async () => fetch('/__/firebase/init.json').then((response) => response.json());

export const useAppState = () => useContext(StateContext);

export const useAppActions = () => {
    const dispatch = useContext(DispatchContext);
    const history = useHistory();
    const { uid, hasPayment } = useAppState();

    if (dispatch === undefined) {
        throw new Error('useAppAction must be used within a AppProvider');
    }

    const setIsInApp = useCallback(
        (value) => {
            dispatch({ type: ActionTypes.SET_IS_IN_APP, payload: value });
        },
        [dispatch]
    );

    const setIsAuthenticate = useCallback(
        (value) => {
            dispatch({ type: ActionTypes.SET_IS_AUTHENTICATE, payload: value });
        },
        [dispatch]
    );

    const loadStatus = useCallback(
        async (userToken) => {
            try {
                const {
                    data: { token, ...userStatus },
                } = await callable('status', { token: userToken });
                if (token) {
                    await signInWithCustomToken(getAuth(), token);
                }
                dispatch({ type: ActionTypes.SET_IS_AUTHENTICATE, payload: true });
                dispatch({ type: ActionTypes.SET_STATUS, payload: userStatus });
                dispatch({ type: ActionTypes.SET_LOCALE, payload: userStatus.locale });
                if (window.location.pathname.indexOf('redirect-payment') !== -1) {
                    return;
                }
                if (!userStatus?.personalDetails.valid) {
                    history.replace('/personal-information');
                } else if (userStatus.userServices.length === 0) {
                    history.replace('/selfie/services');
                } else if (
                    !userStatus.userServices.every(
                        (service) => service.name.toLowerCase().includes('bikes')) && !userStatus?.selfieDoc.valid
                ) {
                    history.replace('/selfie/services');
                } else if (
                    !userStatus.userServices.every(
                        (service) => service.name.toLowerCase().includes('bikes')) && !userStatus?.dlDoc.valid
                ) {
                    history.replace('/driving-licence/instructions');
                    //} else if (!userStatus?.idDoc.valid) {
                    //    history.replace('/identity-card/instructions');
                } else if (hasPayment && !userStatus?.paymentMethod.valid) {
                    history.replace('/plans');
                } else {
                    history.replace('/end');
                }
            } catch (error) {
                Bugsnag.notify(error, (event) => {
                    if (uid) {
                        event.setUser(uid);
                    }
                });
                dispatch({ type: ActionTypes.SET_IS_AUTHENTICATE, payload: false });
            }
        },
        [dispatch, history, hasPayment]
    );

    const handleOnAuthStateChanged = useCallback(
        async (token, user) => {
            const isAuthenticate = !!user;
            if (token || isAuthenticate) {
                await loadStatus(token);
            } else {
                dispatch({ type: ActionTypes.SET_IS_AUTHENTICATE, payload: isAuthenticate });
            }
            dispatch({ type: ActionTypes.LOADING_SUCCESS });
        },
        [dispatch, loadStatus]
    );

    const setUid = useCallback((payload) => {
        dispatch({ type: ActionTypes.SET_UID, payload });
    });

    const load = useCallback(
        async (token) => {
            const config = await fetchFirebaseConfig();
            const app = initializeApp(config)
            if (process.env.NODE_ENV === 'development') {
                connectAuthEmulator(getAuth(), 'http://localhost:9099');
            }
            const siteKey = (process.env.REACT_APP_ENV || 'DEV') === 'PROD' ? '6Lf6R2AnAAAAABdl3bQo7A1obp-jZikD5DL6ConC' : '6Ldt1HEmAAAAAHrL5bCj_cUDm9o6gHLoClO8e18V';
            appCheck = initializeAppCheck(app, {
                provider: new ReCaptchaEnterpriseProvider(siteKey),
                isTokenAutoRefreshEnabled: true,
            });
            getAnalytics();

            const onAuthStateChangedUnsubscribe = onAuthStateChanged(getAuth(), async (user) => {
                onAuthStateChangedUnsubscribe();
                setUid(user?.uid);
                await handleOnAuthStateChanged(token, user);
            });
        },
        [handleOnAuthStateChanged]
    );

    const setLocale = useCallback((payload) => {
        dispatch({ type: ActionTypes.SET_LOCALE, payload });
    });

    const setHasStepper = useCallback((payload) => {
        dispatch({ type: ActionTypes.SET_HAS_STEPPER, payload });
    });

    const setStep = useCallback((payload) => {
        dispatch({ type: ActionTypes.SET_STEP, payload });
    });

    const setRedirect = useCallback((value) => {
        history.push(value);
    }, []);

    const addNotes = useCallback((note) => {
        return callable('addNotes', {
            note,
        }).catch(() => console.error('adding note : failure', { note }));
    }, []);

    const finishRegistration = useCallback(async () => {
        const returnTo = sessionStorage.getItem('returnTo');
        // get refresh token
        const { data } = await callable('getRefreshToken');
        if (returnTo) {
            dispatch({ type: ActionTypes.SET_IS_WAITING, payload: true });
            sessionStorage.removeItem('returnTo');
            dispatch({ type: ActionTypes.SET_IS_WAITING, payload: false });
            return onFinish(returnTo, data);
        }
        return onFinish(null, data);
    }, []);

    return useMemo(
        () => ({
            load,
            setIsInApp,
            loadStatus,
            setIsAuthenticate,
            setLocale,
            setHasStepper,
            setStep,
            setRedirect,
            setUid,
            addNotes,
            finishRegistration,
        }),
        [load, setIsInApp, loadStatus, setLocale, setHasStepper, setStep, setRedirect, setUid, addNotes, finishRegistration]
    );
};

export const useTranslations = () => {
    const { formatMessage, ...rest } = useIntl();
    return {
        t: (id) => formatMessage({ id }),
        formatMessage,
        ...rest,
    };
};
