import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const theme = createTheme({
    typography: {
        fontFamily: 'Poppins',
        h1: {
            fontSize: 96,
            fontWeight: 'light',
            letterSpacing: '-1.5px',
        },
        h2: {
            fontSize: 60,
            fontWeight: 'light',
            letterSpacing: '-0.5px',
        },
        h3: {
            fontSize: 48,
            fontWeight: 'normal',
            letterSpacing: 0,
        },
        h4: {
            fontSize: 34,
            fontWeight: 'bold',
            letterSpacing: '0.25px',
        },
        h5: {
            fontSize: 24,
            fontWeight: 'normal',
            letterSpacing: 0,
        },
        h6: {
            fontSize: 20,
            fontWeight: 'medium',
            letterSpacing: '0.15px',
        },
        body1: {
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: '0.5px',
            color: 'inherit',
        },
        body2: {
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: '0.25px',
        },
        subtitle1: {
            fontSize: 16,
            fontWeight: 'normal',
            letterSpacing: '0.15px',
        },
        subtitle2: {
            fontSize: 14,
            fontWeight: 'normal',
            letterSpacing: '0.1px',
        },
        button: {
            fontSize: 14,
            fontWeight: 'medium',
            letterSpacing: '1.25px',
        },
        overline: {
            fontSize: 10,
            fontWeight: 'normal',
            letterSpacing: '1.5px',
        },
        caption: {
            fontSize: 12,
            fontWeight: 'normal',
            letterSpacing: '0.4px',
        },
        gutterBottom: {
            marginBottom: '2em',
        },
    },
    palette: {
        primary: {
            main: '#1963FF',
            light: '#009aff',
            dark: '#2e3be0',
        },
        background: {
            mainAlert: '#e6d9f7',
        },
        default: {
            main: grey[300],
            contrastText: grey[900],
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                maxWidthXs: {
                    padding: '24px 16px 16px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontWeight: 700,
                },
                contained: {
                    '&:disabled': {
                        backgroundColor: '#bae1ff',
                        color: '#fff',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: '0.7em',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.54)',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                selectMenu: {
                    whiteSpace: 'normal',
                },
            },
        },
    },
});

export default theme;
